<template>
  <div id="tratamentos-corporais" class="tratamentos">
    <div class="container">
      <div class="row">
        <div
          v-for="(tratamento, index) in tratamentos"
          :key="index"
          class="
            tratamento
            col-6 col-md-4 col-lg-3
            d-flex
            flex-column
            justify-content-start
          "
        >
          <div
            @click="openModal(tratamento)"
            :style="`background-image: url('${tratamento.imagem_corporal.url}')`"
            :title="tratamento.imagem_corporal.alt"
            class="img-thumbnail rounded-circle img mb-2"
          ></div>

          <small
            @click="openModal(tratamento)"
            class="titulo text-center mb-5"
            >{{ tratamento.titulo_corporal }}</small
          >
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <router-link
            tag="button"
            to="/tratamentos-corporais"
            class="btn btn-success btn-lg m-auto d-block mb-5"
          >
            Ver mais
          </router-link>
        </div>
      </div>
    </div>

    <Modal v-if="showModal" @close="showModal = false">
      <h3 slot="header">{{ TratamentoEscolhido.titulo_corporal }}</h3>

      <template slot="body">
        <div class="row">
          <div class="col-12 col-md-4">
            <img
              :src="TratamentoEscolhido.imagem_corporal.url"
              :title="TratamentoEscolhido.imagem_corporal.alt"
              class="mb-4 mb-md-0 m-auto d-block"
            />
          </div>
          <div class="col-12 col-md-8 descricao">
            <div v-html="TratamentoEscolhido.descricao_corporal"></div>
            <div clas="mt-3">
              <a
                :href="`https://api.whatsapp.com/send?1=pt_BR&phone=55${infos.celular_de_contato.replace(
                  /[^0-9]/g,
                  ''
                )}&text=${
                  infos.texto_de_mensagem_para_whatsapp
                } para o serviço de ${
                  tratamentoEscolhidoCorporal.titulo_corporal
                }!`"
                target="_blank"
                rel="noopener noreferrer"
                @click="trackWpp()"
              >
                <font-awesome-icon icon="fab fa-whatsapp" /> solicite agora
                mesmo um agendamento por WhatsApp</a
              >
            </div>
          </div>
        </div>
      </template>

      <template slot="footer">
        <button
          class="btn btn-success m-auto m-md-0 d-block"
          @click="clearTratamentoEscolhidoCorporal()"
        >
          Clique aqui para ver todos os tratamentos
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import trackWpp from "@/helpers/conversionGtag";

import Modal from "../Modal";

export default {
  name: "TratamentosCorporais",
  components: {
    Modal,
  },
  data() {
    return {
      showModal: false,
      TratamentoEscolhido: Object,
    };
  },
  computed: {
    ...mapState(["infos", "tratamentoEscolhidoCorporal"]),
    tratamentos() {
      return this.infos.lista_de_tratamentos_corporais.filter(
        (i) => i.mostrar_na_home_do_site_corporal === true
      );
    },
  },
  methods: {
    trackWpp,
    ...mapMutations(["setTratamentoCorporal"]),
    openModal(tratamento) {
      this.TratamentoEscolhido = tratamento;

      this.setTratamentoCorporal(tratamento);
      this.showModal = true;
    },

    clearTratamentoEscolhidoCorporal() {
      this.setTratamentoCorporal("");
      this.$router.push("/tratamentos-corporais");
    },
  },
};
</script>
<template>
  <footer id="rodape" class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-3">
          <h1 class="logo">
            <img
              :src="infos.imagens_padroes.logo_padrao_sem_marca.url"
              :alt="infos.logo_padrao.title"
              class="d-block d-md-none"
            />
            <img
              :src="infos.imagens_padroes.logo_padrao.url"
              :alt="infos.logo_padrao.title"
              class="d-none d-md-block"
            />
          </h1>
        </div>

        <div class="texto col-12 col-lg-6 text-white mt-4 mt-lg-0">
          <div v-html="infos.texto_do_rodape"></div>
        </div>

        <div class="social col-12 col-md-3 mt-4 mt-lg-0">
          <h3 class="copy mb-2">Siga a gente no instagram:</h3>
          <img :src="infos.qrcode_do_instagram" />
          <div class="row">
            <div class="col-12">
              <h3 class="copy my-2">Nossas redes sociais:</h3>
            </div>
            <div class="col-1 col-md-3">
              <a
                :href="`http://www.facebook.com/${infos.perfil_facebook}`"
                target="_blank"
                rel="noopener noreferrer"
                ><font-awesome-icon icon="fab fa-facebook"
              /></a>
            </div>
            <div class="col-1 col-md-3">
              <a
                :href="`http://www.instagram.com/${infos.perfil_instagram}`"
                target="_blank"
                rel="noopener noreferrer"
                ><font-awesome-icon icon="fab fa-instagram"
              /></a>
            </div>
            <div class="col-1 col-md-3">
              <a
                :href="`http://www.tiktok.com/${infos.perfil_tiktok}`"
                target="_blank"
                rel="noopener noreferrer"
                ><font-awesome-icon icon="fab fa-tiktok"
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <p class="copy">&copy; Todos os direitos reservados - {{ year }}</p>
        <p class="copy">
          desenvolvido por
          <a
            href="http://www.compilei.co"
            target="_blank"
            rel="noopener noreferrer"
            >Compilei.co</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Footer",
  computed: {
    ...mapState(["infos"]),
    year() {
      return new Date().getFullYear("Y");
    },
  },
};
</script>
<template>
  <div id="contato" class="contatos container mb-5">
    <div class="row">
      <div class="col-12 col-lg-6">
        <a
          :href="infos.link_do_google_maps_contatos"
          class="mapa"
          target="_blank"
        >
          <img
            :src="infos.mapa_do_local_contatos.url"
            :alt="infos.mapa_do_local_contatos.alt"
          />
        </a>
      </div>
      <div class="dados col-12 col-lg-6 mt-5 mt-lg-0">
        <div class="endereco">
          <h3>{{ infos.subtitulos_contatos[0].subtitulo }}</h3>
          <div v-html="infos.endereco_estetica"></div>
        </div>
        <div class="horario">
          <h3>{{ infos.subtitulos_contatos[1].subtitulo }}</h3>
          <p>
            <span>{{ horarioSemana }}</span> -
            <span>{{ horarioFinalSemana }}</span>
          </p>
          <p
            :class="[
              'statusHorario',
              `${statusComercio === 'Aberto' ? 'open' : 'close'}`,
            ]"
          >
            <span
              :class="[
                'circle-horario',
                `${statusComercio === 'Aberto' ? 'open' : 'close'}`,
              ]"
            ></span>
            <span> {{ statusComercio }}</span>
          </p>
        </div>
        <div class="atendimento">
          <h3>{{ infos.subtitulos_contatos[2].subtitulo }}</h3>
          <p>
            <font-awesome-icon icon="fas fa-phone" />
            <a
              :href="`tel:${infos.telefone_de_contato}`"
              target="_self"
              rel="noopener noreferrer"
              >{{ infos.telefone_de_contato }}</a
            >
          </p>
          <p>
            <font-awesome-icon icon="fab fa-whatsapp" />
            <a
              :href="`https://api.whatsapp.com/send?1=pt_BR&phone=55${infos.celular_de_contato.replace(
                /[^0-9]/g,
                ''
              )}&text=${infos.texto_de_mensagem_para_whatsapp}`"
              target="_blank"
              rel="noopener noreferrer"
              @click="trackWpp()"
              >{{ infos.celular_de_contato }}</a
            >
          </p>
          <p>
            <font-awesome-icon icon="fas fa-envelope" />
            <a
              :href="`mailto:${infos.email_de_contato}`"
              target="_self"
              rel="noopener noreferrer"
              >{{ infos.email_de_contato }}</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import trackWpp from "@/helpers/conversionGtag";

export default {
  name: "Contatos",
  data() {
    return {
      statusComercio: "",
    };
  },
  computed: {
    ...mapState(["infos"]),
    horarioSemana() {
      return `Seg. a Sex. das ${this.infos.atendimento_estetica.horario_de_atendimento_abertura}h às ${this.infos.atendimento_estetica.horario_de_atendimento_fechamento}h`;
    },
    horarioFinalSemana() {
      return `Sábados das ${this.infos.atendimento_estetica_final.horario_de_atendimento_abertura}h às ${this.infos.atendimento_estetica_final.horario_de_atendimento_fechamento}h`;
    },
  },
  methods: {
    trackWpp,
    statusFuncionamento() {
      let dataAtual = new Date();
      let diaDaSemana = dataAtual.getDay();
      let horarioAtual = dataAtual.getHours();

      // Horário Dia de Semana
      let horarioAbertura = Number(
        this.infos.atendimento_estetica.horario_de_atendimento_abertura
      );
      let horarioFechamento = Number(
        this.infos.atendimento_estetica.horario_de_atendimento_fechamento
      );
      let horarioAberturaDeSemana =
        horarioAtual >= horarioAbertura && horarioAtual < horarioFechamento;

      let horarioFechadoDeSemana = horarioAtual >= horarioFechamento;

      // Horário Final de Semana
      let horarioAberturaF = Number(
        this.infos.atendimento_estetica_final.horario_de_atendimento_abertura
      );
      let horarioFechamentoF = Number(
        this.infos.atendimento_estetica_final.horario_de_atendimento_fechamento
      );
      let horarioaberturaDeFSemana =
        horarioAtual >= horarioAberturaF && horarioAtual < horarioFechamentoF;

      // Horário Dia de Semana
      if (horarioAberturaDeSemana && diaDaSemana >= 1 && diaDaSemana <= 5) {
        this.statusComercio = `Aberto`;
      } else if (
        !horarioAberturaDeSemana &&
        horarioFechadoDeSemana &&
        diaDaSemana >= 1 &&
        diaDaSemana < 5
      ) {
        this.statusComercio = `Fechado. Abriremos amanhã às ${horarioAbertura}hs`;
      } else if (
        !horarioAberturaDeSemana &&
        horarioFechadoDeSemana &&
        diaDaSemana === 5
      ) {
        this.statusComercio = `Fechado. Abriremos amanhã às ${horarioAberturaF}hs`;
      } else if (
        !horarioAberturaDeSemana &&
        diaDaSemana >= 1 &&
        diaDaSemana < 5
      ) {
        this.statusComercio = `Fechado. Abriremos hoje às ${horarioAbertura}hs`;
      }

      // Horário Final de Semana
      if (horarioaberturaDeFSemana && diaDaSemana === 6) {
        this.statusComercio = `Aberto`;
      } else if (
        !horarioaberturaDeFSemana &&
        (diaDaSemana === 6 || diaDaSemana === 0)
      ) {
        this.statusComercio = `Fechado. Abriremos na segunda às ${horarioAbertura}hs`;
      }
    },
  },
  mounted() {
    this.statusFuncionamento();
  },
};
</script>

<style>
</style>

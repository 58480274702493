<template>
  <section id="banner_carousel">
    <carousel
      :autoplay="true"
      :loop="true"
      :autoplayTimeout="6000"
      :autoplayHoverPause="true"
      :per-page="1"
      :mouse-drag="false"
      :paginationEnabled="false"
      :navigationEnabled="true"
      :navigationNextLabel="'>'"
      :navigationPrevLabel="'<'"
    >
      <slide
        v-for="imagem in infos.imagens_do_banner"
        :key="imagem.imagem_banner.id"
      >
        <img
          :src="imagem.imagem_banner.url"
          class="w-100 image-banner"
          :alt="imagem.imagem_banner.alt"
        />
        <div class="titulo-banner" :id="`banner_${imagem.imagem_banner.id}`">
          <p v-html="imagem.descricao_banner"></p>
        </div>
      </slide>
    </carousel>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Banner",
  data() {
    return {};
  },
  computed: {
    ...mapState(["infos"]),
  },
  methods: {},
};
</script>

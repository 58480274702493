<template>
  <div :class="['divisor', `${estilo[0]}`]">
    <h2 :class="['titulo', `${estilo[1]}`]">
      <span>{{ titulo }}</span>
      <br />
      <span :class="['icon', `${estilo[1]}`]">
        <font-awesome-icon icon="fa-solid fa-fan" />
      </span>
    </h2>
  </div>
</template>

<script>
export default {
  name: "Divisor",
  props: {
    titulo: String,
    estilo: Array,
  },
  computed: {},
  methods: {},
};
</script>

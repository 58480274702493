<template>
  <div id="sobre" class="container sobre">
    <div class="row">
      <div class="col-12 col-lg-3 col-xl-4">
        <img
          :src="infos.imagem_principal_sobre.url"
          :alt="infos.imagem_principal_sobre.alt"
          class="criador mb-3 mb-lg-0"
        />
      </div>
      <div class="col-12 col-lg-9 col-xl-8">
        <div
          v-html="infos.descricao_sobre"
          class="descricao text-center text-lg-start"
        ></div>
      </div>
      <div class="col-12 mt-4">
        <carousel
          :autoplay="true"
          :loop="true"
          :autoplayTimeout="6000"
          :autoplayHoverPause="true"
          :mouse-drag="true"
          :paginationEnabled="true"
          :navigationEnabled="false"
          :perPageCustom="[
            [480, 2],
            [768, 3],
          ]"
        >
          <slide
            v-for="imagem in infos.imagens_do_slide_sobre"
            :key="imagem.imagem_slide_sobre.id"
          >
            <img
              :src="imagem.imagem_slide_sobre.url"
              class="w-100 image-banner"
              :alt="imagem.imagem_slide_sobre.alt"
            />
            <div
              class="titulo-banner"
              :id="`banner_${imagem.imagem_slide_sobre.id}`"
            >
              <p v-html="imagem.descricao_banner"></p>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Sobre",
  computed: {
    ...mapState(["infos"]),
  },
};
</script>

<style>
</style>
<template>
  <a
    :href="`https://api.whatsapp.com/send?1=pt_BR&phone=55${
      infos ? infos.celular_de_contato.replace(/[^0-9]/g, '') : ''
    }&text=${infos ? infos.texto_de_mensagem_para_whatsapp : ''}`"
    :class="[
      'whatsapp-fixed',
      'animate__animated',
      'animate__tada',
      'animate__fast',
      `${infos ? 'active' : ''}`,
    ]"
    target="_blank"
    rel="noopener noreferrer"
    data-wpp
    @click="trackWpp()"
  >
  </a>
</template>

<script>
import { mapState } from "vuex";
import trackWpp from "@/helpers/conversionGtag";

export default {
  name: "WhatsApp",
  computed: {
    ...mapState(["infos"]),
  },
  methods: {
    trackWpp,
    timeAnimation() {
      let node = document.querySelector("[data-wpp]");

      setInterval(() => {
        if (!node.classList.contains("animate__tada")) {
          node.classList.add("animate__tada");
        } else {
          node.classList.remove("animate__tada");
        }
      }, 2000);
    },
  },
  mounted() {
    this.timeAnimation();
  },
};
</script>

<style>
</style>

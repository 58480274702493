export default function debounce(fn, wait) {
  var timeout;
  return function () {
    var context = this;
    var args = arguments;

    clearTimeout(timeout);

    timeout = setTimeout(function () {
      fn.apply(context, args);
    }, wait);
  };
};
<template>
  <div id="home">
    <div v-if="loading">
      <!-- MiniHeader -->
      <MiniHeader />

      <!-- Header -->
      <Header />

      <!-- Banner -->
      <Banner />

      <!-- Divisor -->
      <Divisor :titulo="infos.titulo_sobre" :estilo="['', '']" />

      <!-- Quem Somos -->
      <Sobre />

      <!-- Divisor -->
      <Divisor
        :titulo="infos.titulo_tratamentos_corporais"
        :estilo="['', '']"
      />

      <!-- Seção Tratamentos Corporais -->
      <TratamentosCorporais />

      <!-- Divisor -->
      <Divisor
        :titulo="infos.titulo_tratamentos_faciais"
        :estilo="['dark', 'texto-dark']"
      />

      <!-- Seção Tratamentos Faciais -->
      <TratamentosFaciais />

      <!-- Divisor -->
      <Divisor :titulo="infos.titulo_contatos" :estilo="['', '']" />

      <Contatos />

      <!-- Footer -->
      <Footer />
    </div>
    <Loading v-else />
  </div>
</template>

<script>
// Componentes
import Header from "../components/Header";
import MiniHeader from "../components/MiniHeader";
import Banner from "../components/Banner";
import Divisor from "../components/Divisor";
import Footer from "../components/Footer";
import Loading from "../components/Loading";

// Seções
import Sobre from "../components/sections/Sobre";
import TratamentosCorporais from "../components/sections/TratamentosCorporais";
import TratamentosFaciais from "../components/sections/TratamentosFaciais";
import Contatos from "../components/sections/Contatos";

import { mapState, mapActions } from "vuex";

export default {
  name: "HomeView",
  components: {
    Header,
    MiniHeader,
    Banner,
    Divisor,
    Footer,
    Loading,
    Sobre,
    TratamentosCorporais,
    TratamentosFaciais,
    Contatos,
  },
  computed: {
    ...mapState(["loading", "infos"]),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getOptionsWpDefault"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title;
      },
    },
  },
  created() {},
  updated() {},
  mounted() {
    this.getOptionsWpDefault();
  },
};
</script>

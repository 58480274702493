<template>
  <div
    class="loading d-flex justify-content-center align-items-center flex-column"
  >
    <span
      class="
        h1
        mb-3
        d-block
        animate__animated animate__rotateOut animate__infinite
      "
      ><font-awesome-icon icon="fa-solid fa-spinner"
    /></span>
    <span class="small d-block fw-bold">Carregando...</span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.loading {
  background-color: #ffffff;
  min-height: 100vh;
  z-index: 9999;
}
</style>
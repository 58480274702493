<template>
  <div class="mini_header text-center p-2">
    <p class="m-0 d-none d-md-block">
      Ligue agora e marque seu atendimento: {{ infos.telefone_de_contato }} |
      {{ infos.celular_de_contato }}
    </p>
    <p class="m-0 d-block d-md-none">
      Fixo: {{ infos.telefone_de_contato }} Celular:
      {{ infos.celular_de_contato }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MiniHeader",
  computed: {
    ...mapState(["infos"]),
  },
};
</script>

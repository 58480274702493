import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    infos: null,
    loading: false,
    tratamentoEscolhidoFacial: null,
    tratamentoEscolhidoCorporal: null,
    titleEscolha: "Escolha um Tratamento",
  },
  mutations: {
    setInfos(state, payload) {
      state.infos = payload;
      state.loading = true;
    },
    setTratamentoFacial(state, payload) {
      state.tratamentoEscolhidoFacial = payload;
    },
    setTratamentoCorporal(state, payload) {
      state.tratamentoEscolhidoCorporal = payload;
    },
    setTitle(state, payload) {
      state.titleEscolha = payload;
    },
  },
  actions: {
    getOptionsWpDefault({ commit }) {
      axios(`wp-json/acf/v3/options/options`)
        .then((response) => {
          commit('setInfos', response.data.acf);
          commit('setTratamentoFacial', response.data.acf.lista_de_tratamentos_faciais[0]);
          commit('setTratamentoCorporal', response.data.acf.lista_de_tratamentos_corporais[0]);
        })
        .catch((error) => alert(error + " Tente novamente mais tarde!"));
    },
  },
  modules: {
  }
})

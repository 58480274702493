<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="container modal-container rounded-3">
          <div class="modal-header">
            <slot name="header"> default header </slot>

            <div
              class="modal-default-button d-flex align-items-center"
              @click="$emit('close')"
            >
              <font-awesome-icon icon="fas fa-times" />
              <span class="ms-2 d-inline-block">Fechar</span>
            </div>
          </div>

          <div class="modal-body" style="overflow-y: auto">
            <slot name="body"> default body </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"> default footer </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-wrap"},[_c('header',{class:['py-3', 'py-lg-4', ("" + (_vm.fixed ? 'fixed' : ''))],attrs:{"id":"menu-principal"}},[_c('nav',{staticClass:"container"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h1',{staticClass:"logo"},[_c('img',{staticClass:"d-block d-md-none",attrs:{"src":_vm.infos.imagens_padroes.logo_padrao_sem_marca.url,"alt":_vm.infos.logo_padrao.title}}),_c('img',{staticClass:"d-none d-md-block",attrs:{"src":_vm.infos.imagens_padroes.logo_padrao.url,"alt":_vm.infos.logo_padrao.title}})]),(!_vm.hideMenu)?_c('div',{staticClass:"d-flex align-items-center flex-column cursor"},[_c('div',{staticClass:"hamburger d-flex d-lg-none",attrs:{"data-hamburger":""},on:{"click":function($event){return _vm.openMenu($event)}}},[_c('span'),_c('span'),_c('span')]),_c('span',{staticClass:"text-white d-lg-none text-center mt-2 d-block"},[_vm._v("Menu")]),_c('ul',{class:[
              'menu',
              'd-flex',
              'justify-content-center',
              'align-items-center',
              'flex-column flex-lg-row',
              ("" + (_vm.initial ? 'initial' : 'animate__animated')),
              ("" + (_vm.open ? 'animate__backInDown' : 'animate__fadeOutDown')) ]},[_vm._l((_vm.infos.itens_do_menu),function(item,index){return _c('li',{key:index,staticClass:"me-2"},[_c('router-link',{attrs:{"to":("#" + (item.url_menu))},nativeOn:{"click":function($event){return _vm.openMenu($event)}}},[_vm._v(_vm._s(item.nome_menu))])],1)}),_c('li',{staticClass:"mt-5 d-block d-lg-none",on:{"click":function($event){return _vm.openMenu()}}},[_c('span',[_c('font-awesome-icon',{attrs:{"icon":"fas fa-times"}}),_vm._v(" Fechar menu "),_c('font-awesome-icon',{attrs:{"icon":"fas fa-times"}})],1)])],2)]):_c('router-link',{staticClass:"voltar d-flex flex-column",attrs:{"to":"/"}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-rotate-left"}}),_c('span',{staticClass:"mt-2"},[_vm._v("Voltar")])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="header-wrap">
    <header
      id="menu-principal"
      :class="['py-3', 'py-lg-4', `${fixed ? 'fixed' : ''}`]"
    >
      <nav class="container">
        <div class="d-flex justify-content-between align-items-center">
          <h1 class="logo">
            <img
              :src="infos.imagens_padroes.logo_padrao_sem_marca.url"
              :alt="infos.logo_padrao.title"
              class="d-block d-md-none"
            />
            <img
              :src="infos.imagens_padroes.logo_padrao.url"
              :alt="infos.logo_padrao.title"
              class="d-none d-md-block"
            />
          </h1>

          <div
            v-if="!hideMenu"
            class="d-flex align-items-center flex-column cursor"
          >
            <div
              class="hamburger d-flex d-lg-none"
              data-hamburger
              @click="openMenu($event)"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>

            <span class="text-white d-lg-none text-center mt-2 d-block"
              >Menu</span
            >

            <ul
              :class="[
                'menu',
                'd-flex',
                'justify-content-center',
                'align-items-center',
                'flex-column flex-lg-row',
                `${initial ? 'initial' : 'animate__animated'}`,
                `${open ? 'animate__backInDown' : 'animate__fadeOutDown'}`,
              ]"
            >
              <li
                v-for="(item, index) in infos.itens_do_menu"
                :key="index"
                class="me-2"
              >
                <router-link
                  :to="`#${item.url_menu}`"
                  @click.native="openMenu($event)"
                  >{{ item.nome_menu }}</router-link
                >
              </li>

              <li class="mt-5 d-block d-lg-none" @click="openMenu()">
                <span
                  ><font-awesome-icon icon="fas fa-times" /> Fechar menu
                  <font-awesome-icon icon="fas fa-times"
                /></span>
              </li>
            </ul>
          </div>

          <router-link v-else to="/" class="voltar d-flex flex-column">
            <font-awesome-icon icon="fa-solid fa-rotate-left" />
            <span class="mt-2">Voltar</span>
          </router-link>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import { mapState } from "vuex";
import debounce from "@/helpers/debounce";

export default {
  name: "Header",
  data() {
    return {
      open: false,
      fixed: false,
      initial: true,
    };
  },
  props: {
    hideMenu: false,
  },
  computed: {
    ...mapState(["infos"]),
  },
  methods: {
    openMenu() {
      this.open = !this.open;
      this.initial = false;
    },
    fixedMenu() {
      let positionCurrent = 350;

      let scrollOpt = debounce(() => {
        let windowPosition = window.scrollY;
        if (windowPosition >= positionCurrent) this.fixed = true;
        else this.fixed = false;
      }, 200);

      window.addEventListener("scroll", scrollOpt);
    },
  },
  mounted() {
    this.fixedMenu();
  },
};
</script>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCarousel from 'vue-carousel';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faWhatsapp, faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFan, faPhone, faEnvelope, faTimes, faRotateLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import VueGtag from "vue-gtag";

// CSS
import 'animate.css';
library.add(faFan, faPhone, faEnvelope, faTimes, faRotateLeft, faSpinner, faWhatsapp, faFacebook, faInstagram, faTiktok);

Vue.use(VueCarousel);
Vue.use(VueGtag, {
  config: {
    id: "AW-10857030768/X0YwCJ3Wg7EDEPDIhLko",
    params: {
      send_page_view: false
    }
  }
}, router);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

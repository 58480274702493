var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contatos container mb-5",attrs:{"id":"contato"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('a',{staticClass:"mapa",attrs:{"href":_vm.infos.link_do_google_maps_contatos,"target":"_blank"}},[_c('img',{attrs:{"src":_vm.infos.mapa_do_local_contatos.url,"alt":_vm.infos.mapa_do_local_contatos.alt}})])]),_c('div',{staticClass:"dados col-12 col-lg-6 mt-5 mt-lg-0"},[_c('div',{staticClass:"endereco"},[_c('h3',[_vm._v(_vm._s(_vm.infos.subtitulos_contatos[0].subtitulo))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.infos.endereco_estetica)}})]),_c('div',{staticClass:"horario"},[_c('h3',[_vm._v(_vm._s(_vm.infos.subtitulos_contatos[1].subtitulo))]),_c('p',[_c('span',[_vm._v(_vm._s(_vm.horarioSemana))]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(_vm.horarioFinalSemana))])]),_c('p',{class:[
            'statusHorario',
            ("" + (_vm.statusComercio === 'Aberto' ? 'open' : 'close')) ]},[_c('span',{class:[
              'circle-horario',
              ("" + (_vm.statusComercio === 'Aberto' ? 'open' : 'close')) ]}),_c('span',[_vm._v(" "+_vm._s(_vm.statusComercio))])])]),_c('div',{staticClass:"atendimento"},[_c('h3',[_vm._v(_vm._s(_vm.infos.subtitulos_contatos[2].subtitulo))]),_c('p',[_c('font-awesome-icon',{attrs:{"icon":"fas fa-phone"}}),_c('a',{attrs:{"href":("tel:" + (_vm.infos.telefone_de_contato)),"target":"_self","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.infos.telefone_de_contato))])],1),_c('p',[_c('font-awesome-icon',{attrs:{"icon":"fab fa-whatsapp"}}),_c('a',{attrs:{"href":("https://api.whatsapp.com/send?1=pt_BR&phone=55" + (_vm.infos.celular_de_contato.replace(
              /[^0-9]/g,
              ''
            )) + "&text=" + (_vm.infos.texto_de_mensagem_para_whatsapp)),"target":"_blank","rel":"noopener noreferrer"},on:{"click":function($event){return _vm.trackWpp()}}},[_vm._v(_vm._s(_vm.infos.celular_de_contato))])],1),_c('p',[_c('font-awesome-icon',{attrs:{"icon":"fas fa-envelope"}}),_c('a',{attrs:{"href":("mailto:" + (_vm.infos.email_de_contato)),"target":"_self","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.infos.email_de_contato))])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="app">
    <router-view />
    <WhatsApp />
  </div>
</template>

<script>
import WhatsApp from "./components/WhatsApp.vue";

export default {
  components: {
    WhatsApp,
  },
};
</script>


<style lang="scss">
@import "sass/theme.scss";
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home - Estética Vanessa Marinho | Clínica Estética | Tratamentos Eficazes para o Corpo | Santana | São Paulo'
    }
  },
  {
    path: '/tratamentos-faciais/:id?',
    name: 'TratamentosFaciais',
    component: () => import("../views/TratamentosFaciaisView.vue"),
    meta: {
      title: 'Tratamentos Faciais'
    }
  },
  {
    path: '/tratamentos-corporais/:id?',
    name: 'TratamentosCorporais',
    component: () => import("../views/TratamentosCorporaisView.vue"),
    meta: {
      title: 'Tratamentos Corporais'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
        offset: {
          y: 300,
        },
      };
    } else {
      window.scrollTo(0, 0);
    }
  },
})

export default router
